<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>用户协议</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="foot-detail">
      <h3>用户协议</h3>
      <p><b>1.特别说明</b></p>

      <p>
        车学堂机动车驾驶理论培训平台由人民交通出版社股份有限公司所有，由北京行翼科技有限公司负责运营，旨在提供为机动车驾驶人和道路运输从业人员提供权威、专业、系统、全面的网络远程培训服务。
      </p>

      <p>
        车学堂有权随时修改本协议的任何条款，一旦本协议的内容发生变动，车学堂将会直接在车学堂网站上公布修改之后的协议内容，该公布行为视为车学堂已经通知用户修改内容。
      </p>

      <p><b>2. 用户承担的责任</b></p>
      <p>
        用户使用车学堂及北京行翼科技有限公司提供的服务，或者点击接受本协议均被视为用户对本协议条款完全接受。
      </p>
      <p>
        （1）用户务必确保用户名及密码的安全性。如果丢失或损坏，造成的损失将由本人承担全部后果。用户对利用该用户名及密码所进行的一切活动负全部责任；因此所衍生的任何损失或损害，车学堂不承担任何责任。
      </p>
      <p>（2）自备上网所需要的设备，自行承担上网产生的各项费用。</p>
      <p>
        （3）必须遵守中华人民共和国的法律、法规、规章、条例、以及其他具有法律效力的规范，不使用网络服务做非法用途。
      </p>
      <p>（4）不得利用软件或网络系统漏洞干扰或混乱网络服务。</p>
      <p>（5）不得侵犯车学堂所有版权以及其它权益。</p>
      <p>
        （6）不得将广告、促销资料等，加以上载、张贴、发送电子邮件或以其他方式传送。
      </p>
      <p>（7）用户对其发布的内容单独承担责任。</p>

      <p><b>3.服务约定</b></p>
      <p>
        （1）车学堂为用户提供的视频资源，用户不得使用软件截取下来作为商品进行销售或共享到其他网站上。
      </p>
      <p>（2）车学堂所提供的试题资源中的图片，用户不得用于其他商业行为。</p>
      <p>
        （3）车学堂提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。车学堂不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。
      </p>

      <p><b>4.服务变更、中断或终止</b></p>
      <p>
        （1）鉴于网络服务的特殊性，用户同意车学堂有权随时变更、中断或终止部分或全部的网络服务。如变更、中断或终止的网络服务属于免费网络服务，车学堂无需通知用户，也无需对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，车学堂应当在变更、中断或终止之前事先通知用户。
      </p>
      <p>
        （2）车学堂需要定期或不定期地对提供网络服务的平台等或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，车学堂无需为此承担任何责任。
      </p>
      <p>（3）用户违反本协议中的规定。</p>

      <p><b>5.使用规则</b></p>
      <p>
        （1）用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用，应立即通知车学堂。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，车学堂不承担任何责任。
      </p>
      <p>
        （2）用户同意车学堂有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在车学堂网站的任何页面上投放广告），并且，用户同意接受车学堂通过电子邮件或其他方式向用户发送商品促销或其他相关商业信息。
      </p>
      <p>（3）用户遵循以下原则：</p>
      <p>· 遵守中国有关的法律和法规；</p>
      <p>· 遵守所有与网络服务有关的网络协议、规定和程序；</p>
      <p>· 不得为任何非法目的而使用网络服务系统；</p>
      <p>
        ·
        不得以任何形式使用车学堂网络服务侵犯车学堂的商业利益，包括并不限于发布非经车学堂许可的商业广告；
      </p>
      <p>
        ·
        不得利用车学堂网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；
      </p>
      <p>
        ·
        不得利用车学堂提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；
      </p>
      <p>
        ·
        不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；
      </p>
      <p>· 不得利用车学堂网络服务系统进行任何不利于车学堂的行为。</p>
      <p>
        （4）车学堂有权对用户使用车学堂网络服务的情况进行审查和监督(包括但不限于对用户存储在车学堂的内容进行审核)，如用户在使用网络服务时违反任何上述规定，车学堂或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容等、暂停或终止用户使用网络服务的权利）以减轻用户不当行为造成的影响。
      </p>
      <p>
        （5）车学堂针对某些特定的车学堂网络服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等车学堂网络服务，视为用户同意该等声明、通知、警示的内容。
      </p>
      <p>
        （6）凡用户昵称中出现如下信息者（包括但不限于），车学堂有权进行屏蔽、锁定，甚至注销：使用严重违反国家法律法律及风序良俗，包含或暗藏反动、色情等信息（含符号、地址等）的昵称；
      </p>
      <p>
        ·
        使用党和国家领导人、古今中外比较有影响的名人、网络上知名人士的真实姓名、字、号、艺名、笔名、头衔等为昵称；
      </p>
      <p>· 使用国家机构、企事业单位、知名公司或品牌的名称及标识为昵称；</p>
      <p>· 含性别歧视、种族歧视、地域歧视等信息的昵称；</p>
      <p>
        ·
        含自侮辱、猥亵或者对他人进行侮辱、挑衅、漫骂、人身攻击等不文明、不健康信息的昵称；
      </p>
      <p>· 未获得车学堂官方正式许可而使用带有车学堂相关名称的昵称；</p>
      <p>· 带有明显广告性质的昵称；</p>
      <p>· 带有其它法律法规以及论坛禁止规则中所包含信息的昵称。</p>

      <p><b>6.隐私保护</b></p>
      <p>
        保护用户隐私是车学堂的一项基本政策，车学堂保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在车学堂的非公开内容，但下列情况除外：
      </p>
      <p>（1）事先获得用户的明确授权；</p>
      <p>（2）根据有关的法律法规要求；</p>
      <p>（3）按照相关政府主管部门的要求；</p>
      <p>（4）为维护社会公众的利益；</p>
      <p>（5）为维护车学堂的合法权益。</p>
      <p>
        （6）在不透露单个用户隐私资料的前提下，车学堂有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
